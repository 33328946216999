import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';

import Context, { I18nContextProps } from './Context';

const USD = 'USD';
const defaultCurrencyOptions = [USD, 'GBP', 'EUR'];
const defaultCurrencySymbol = '$';

// TODO: move to a more general helpers file
function isFiniteNumber(n: unknown): n is number {
  return Number.isFinite(n);
}

// TODO: move to a more general helpers file
export function asFloat(n: number | string): number {
  if (isFiniteNumber(n)) return n;

  return parseFloat(n);
}

export function asMoneyHelper(
  n: number | string,
  showFractional = true,
  fractionDigits = 2,
  currency = USD
) {
  if (n === undefined || n === null || Number.isNaN(n)) return '';

  const digits = showFractional ? fractionDigits : 0;

  return asFloat(n).toLocaleString(undefined, {
    style: 'currency',
    currency,
    maximumFractionDigits: digits,
    minimumFractionDigits: digits
  });
}

export function asMoneyReducedHelper(
  n: number | string,
  fractionDigits = 1,
  currency = USD
) {
  const num = asFloat(n);
  const { limit, letter } =
    COUNT_FORMATS.find(({ limit: l }) => num < l) ?? COUNT_FORMATS[0];

  const value = (1000 * num) / limit;

  return asMoneyHelper(value, true, fractionDigits, currency) + letter;
}

export function asMoneyDiffHelper(
  n: number | string,
  showFractional = true,
  fractionDigits = 2,
  currency = USD
) {
  const money = asMoneyHelper(n, showFractional, fractionDigits, currency);

  if (asFloat(n) < 0) return money;

  return `+${money}`;
}
type ProviderProps = {
  children: ReactNode;
};
const Provider: FC<ProviderProps> = ({ children }) => {
  const [currencyOptions, setCurrencyOptions] = useState(
    defaultCurrencyOptions
  );
  const [currency, setCurrency] = useState(defaultCurrencyOptions[0]);
  const [currencySymbol, setCurrencySymbol] = useState(defaultCurrencySymbol);

  const asMoney = useCallback<I18nContextProps['asMoney']>(
    (n, showFractional = true, fractionDigits = 2) =>
      asMoneyHelper(n, showFractional, fractionDigits, currency),
    [currency]
  );

  const asMoneyDiff = useCallback<I18nContextProps['asMoneyDiff']>(
    (n, showFractional = true, fractionDigits = 2) =>
      asMoneyDiffHelper(n, showFractional, fractionDigits, currency),
    [currency]
  );

  const asMoneyReduced = useCallback<I18nContextProps['asMoneyReduced']>(
    (n, fractionDigits = 1) =>
      asMoneyReducedHelper(n, fractionDigits, currency),
    [currency]
  );

  const handleSetCurrencyOptions = useCallback(
    (options?: string[]) => {
      const updatedOptions = options?.length ? options : defaultCurrencyOptions;
      const optionsSet = new Set(updatedOptions);

      // should we always include USD here?
      // if (!optionsSet.has(USD)) updatedOptions.push(USD);

      setCurrencyOptions(updatedOptions);

      if (optionsSet.has(currency)) return;

      setCurrency(updatedOptions[0] ?? USD); // not sure why [0] would ever be null
    },
    [currency]
  );

  const initialProviderValue: I18nContextProps = useMemo(
    () => ({
      currency,
      setCurrency,
      currencyOptions,
      setCurrencyOptions: handleSetCurrencyOptions,
      currencySymbol,

      asMoney,
      asMoneyDiff,
      asMoneyReduced
    }),
    [
      currency,
      currencyOptions,
      currencySymbol,
      handleSetCurrencyOptions,
      asMoney,
      asMoneyDiff,
      asMoneyReduced
    ]
  );

  useEffect(() => {
    const formatter = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: 'USD' // Default currency
    });
    const userCurrency = formatter.resolvedOptions().currency;

    const symbol = formatter
      .formatToParts(1)
      .find((x) => x.type === 'currency')?.value;
    if (symbol) setCurrencySymbol(symbol);

    // Set the currency if it's in the options
    if (!userCurrency) return;

    if (!currencyOptions.includes(userCurrency)) {
      setCurrencyOptions((prev) => [userCurrency, ...prev]);
    }
    setCurrency(userCurrency);
  }, [currencyOptions]); // Dependency array includes currencyOptions

  console.log({ initialProviderValue });

  return (
    <Context.Provider value={initialProviderValue}>{children}</Context.Provider>
  );
};

export default Provider;

// Configuration
const COUNT_FORMATS = [
  {
    // 0 - 999
    letter: '',
    limit: 1e3
  },
  {
    // 1,000 - 999,999
    letter: 'K',
    limit: 1e6
  },
  {
    // 1,000,000 - 999,999,999
    letter: 'M',
    limit: 1e9
  },
  {
    // 1,000,000,000 - 999,999,999,999
    letter: 'B',
    limit: 1e12
  },
  {
    // 1,000,000,000,000 - 999,999,999,999,999
    letter: 'T',
    limit: 1e15
  }
];
